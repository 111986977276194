@import "../../typography.css";
@import "../../mixins.css";

.component {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
}

.headerContent {
  @mixin background_primary;
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.logoContainer {
  text-align: center;
  position: absolute;
  padding: 16px 0 0 25px;
  left: 0;

  a {
    display: inline-block;
  }
}

.logoIcon {
  svg {
    height: 30px;
    width: 152px;

    @media (--smartphone-and-tablet) {
      width: 125px;
    }
  }
}

.stagingLogoIcon {
  svg {
    height: 20px;
    width: 152px;

    @media (--smartphone-and-tablet) {
      width: 125px;
    }
  }
}

.stagingHeader {
  background-color: #df5533 !important;
}

.stagingHeaderText {
  color: black;
  font-weight: bold;
  font-size: 20px;
  font-family: sans-serif;
}

.nav {
  width: 100%;
  text-align: right;
}
