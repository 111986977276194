@import "../typography.css";
@import "../mixins.css";

.component {
  @mixin bodyText;
  display: flex;
  justify-content: space-between;
  padding: 15px 45px 10px 15px;
  background-color: #fff;
  border-style: none;
  position: relative;
  height: 100%;
  width: 100%;

  &.error {
    @mixin text_error;
  }

  &.success {
    @mixin text_success;
  }
}

.close {
  border: none;

  .icon {
    width: 13px;
    svg path {
      @mixin stroke_disabled;
      stroke-width: 2;
    }
  }
}
