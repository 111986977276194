.component {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 300ms linear;
}

.leave {
  opacity: 1;
}

.leaveActive {
  opacity: 0;
  transition: opacity 300ms linear;
}
