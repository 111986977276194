@import "../typography.css";
@import "../mixins.css";

.component {
  line-height: 50px;

  a {
    @mixin captionDescriptionText;
    @mixin text_primary;
  }

  @media (--desktop) {
    flex-basis: 38%;
  }
}

.slash {
  padding: 0 10px;
}

.disabledItem {
  @mixin captionDescriptionText;
  @mixin text_secondary;
  display: inline;
}

.secondary {
  padding: 0;
  line-height: 24px;
}

.header {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;

  > span {
    @mixin background_primary;
    position: relative;
    z-index: 2;
  }
}

.breadcrumbItem{ 
  &:only-of-type {
    display: none;
  }
}

.hideOnDesktop {
  @mixin hide_on_desktop;
}

.hideOnSmartPhoneAndTablet {
  @mixin hide_on_smartphone_and_tablet;
}
