@import "../../mixins.css";

.component {
  text-align: center;
}

.auth-button-wrapper {
  @mixin background_primary;
  margin: auto;
  width: 190px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px !important;
  display: flex;
  align-items: center;
  gap: $spacingXs;
  padding: $spacingXs;
  border-radius: 2px;
  border: 1px solid transparent;
}