@import "../fonts.css";
@import "../mixins.css";

/* requires transforming with postcss-prefix-selector in postcss config to be prefixed with .component :global */
@import "react-sortable-tree/style.css";

.subHeaderContainer {
  position: fixed;
  z-index: 8;
  top: 60px;
  left: 0;
  right: 0;
  box-shadow: 0 1px 1px 0 #E5E5E5;
  height: $spacingXl;
  @mixin background_primary;
  padding: 0 $spacingLg;
}

.component {
  margin-top: 140px;
  padding: 0 $spacingMd $spacingMd $spacingMd;
  background: #F6F6F6;

  h1,h2,h3,h4 {
    @mixin typePrimary;
    @mixin text_tertiary;
    font-weight: normal;
  }

  :global .ReactTable {
    margin-top: $spacingLg;
    padding: 0 $spacingLg;
  }
}
